/*
Theme Name: ADD ASSOCIÉS
Description: ADD ASSOCIÉS
Version: 1.1
Author: Piotr Sember
Author URI: http://millstudio.pl
*/

/** Portron Clovis (portron.cl@gmail.com) */

*
{
  font-family: $gotham !important;
}
html{
  scroll-behavior: smooth;
}
h1,h2, h3, h4, h5
{
  font-family: $gotham !important;
  font-weight: 300 !important;
}

#langs
{
  margin-top: 25px;
}

/* Page à propos tt langues et enfants */
.page-id-17 #banner, .page-id-19 #banner, .page-id-21 #banner,
.page-id-1000 #banner, .page-id-1020 #banner, .page-id-1006 #banner,
.page-id-1990 #banner, .page-id-1979 #banner, .page-id-1975 #banner,
.page-id-1987 #banner, .page-id-1980 #banner, .page-id-1976 #banner,
.page-id-1988 #banner, .page-id-1981 #banner, .page-id-1977 #banner
.page-id-1989 #banner, .page-id-1982 #banner, .page-id-1978 #banner
{background-image: url("images/a-propos.jpg");}


.page-id-23 #banner, .page-id-25 #banner, .page-id-27 #banner, .page-id-29 #banner,
.page-id-1116 #banner, .page-id-1119 #banner, .page-id-1121 #banner, .page-id-1123 #banner, 
.page-id-2233 #banner, .page-id-2226 #banner, .page-id-2215 #banner, .page-id-2211 #banner, 
.page-id-2231 #banner, .page-id-2224 #banner, .page-id-2217 #banner, .page-id-2213 #banner
.page-id-1941 #banner, .page-id-2234 #banner, .page-id-2223 #banner, .page-id-2216 #banner, .page-id-2212 #banner
.page-id-2232 #banner, .page-id-2225 #banner, .page-id-2218 #banner, .page-id-2214 #banner
{background-image: url("images/ENGAGEMENTS.png");}

.page-id-3280 #banner, .page-id-175 #banner, .page-id-3213 #banne, .page-id-854 #banner, .page-id-851 #banner, .page-id-3201 #banner,
.parent-pageid-31 #banner,
.page-id-3027 #banner, .page-id-1199 #banner,
.page-id-3028 #banner, .page-id-2398 #banner,
.page-id-3029 #banner, .page-id-2245 #banner, .page-id-2241 #banner, .page-id-2395 #banner
.page-id-3030 #banner, .page-id-2396 #banner
.page-id-3031 #banner, .page-id-2397 #banner
{background-image: url("images/PUBLICATIONS.png");}

.page-id-127 #banner, .page-id-129 #banner,
.page-id-1146 #banner, .page-id-1148 #banner,
.page-id-2255 #banner, .page-id-2251 #banner,
.page-id-2256 #banner, .page-id-2252 #banner,
.page-id-2257 #banner, .page-id-2253 #banner
.page-id-2258 #banner, .page-id-2254 #banner
{
  background-image: url("images/BUREAUX.png");
}

.page-id-177 #banner,
.page-id-1201 #banner,
.page-id-2387 #banner,
.page-id-2388 #banner,
.page-id-2389 #banner,
.page-id-2390 #banner,
.page-id-3485 #banner
{
  background-image: url("images/FAQ.png");
}



/** Langues sur deux lignes **/
#langs li.icl-it
{
  border: none;
  padding-left: 0;
  margin-left: 0;
}

/** Probono sur deux blocs **/
.probono-wrapper
{
  display: flex;
  text-align: justify;
}

.probono-left
{
  display: inline-block;
  padding: 20px;
}

.probono-right
{
  display: inline-block;
  background-color: #cf0a2c;
  color: white;
  padding: 20px;
}

/** Titre au dessus de la bannière **/
.text-top
{
  text-align: center;color: #CF0A2C;font-size: 2.18em;text-transform: uppercase;
  margin: 20px;
  margin-top: 0px;
  font-family: "Gotham";
}

.text-top #banner-in
{
  width: 100%;
  display: block;
}

/** Changer icone de la souris **/
#scroll-down {
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  -webkit-transition-delay: 1.5s, 0s;
  transition-delay: 1.5s, 0s;
  -webkit-transition-timing-function: ease-out, ease-out;
  transition-timing-function: ease-out, ease-out;
  -webkit-transition-duration: 0.3s, 0.3s;
  transition-duration: 0.3s, 0.3s;
  opacity: 0;
  z-index: 400;
}
.home-js #scroll-down {
  width:30px;height: 64px;background: url("images/arrow.svg") no-repeat 0 0;background-size: 100% auto;position: fixed;bottom: -74px;left: 50%;margin-left: -15px;-webkit-transform:translateY(-84px) translateZ(0px);-ms-transform:translateY(-84px);transform:translateY(-84px) translateZ(0px);
  z-index: 10;
}
.home-loaded #scroll-down {opacity: 1;}
.home-js.scrolled #scroll-down {-webkit-transform: translateY(0px) translateZ(0px);-ms-transform: translateY(0px);transform: translateY(0px) translateZ(0px);}


/** Mailchimp **/
.mailchimp
{
  background-color: white;
}

.mailchimp hr 
{
  color: #a7a8aa;
}

@media screen and (max-width: 600px)
{
  .mailchimp
  {
    margin-top: 70px;
  }
}


#mc-embedded-subscribe-form
{
  background-color: white;
  color: #23282d;
}

#mc_embed_signup h2
{
  margin: 0 !important;
  font-size: 1em !important;
  font-size: 100;
}
#mc_embed_signup .button
{
  font-weight: 300 !important;
 
}

.mc-row
{
  display: flex;
  display: -ms-flexbox;
  display: -webkit-box;

  align-items: end;
  -webkit-box-align: end;
}

.mc-field-group
{
  width: auto;
  padding: 5px;
  -webkit-box-flex: 1;
  padding-bottom: 5px !important;
  color: #CF0A2C;

  font-weight: 300;
}

@media screen and (max-width: 600px)
{
  .mailchimp
  {
    margin-top: 110px;
  }
}


div.mce_inline_error
{
  display: none !important;
}

#mc-embedded-subscribe
{
  padding: 0 !important;
  margin: 0 !important;
  margin-top: 20px !important;
  
}

/** Menu **/

#submenu, #menu {font-size: 0.80em;font-weight: 300;line-height: 1;text-transform: uppercase;}

/** Bureaux **/
#footer-list-france, #footer-list-foreign
{
  list-style: square;
  border-collapse: collapse;
  table-layout: fixed;
  text-align: center;
  margin-bottom: 10px;
}

#footer-list-france li, #footer-list-foreign li
{
  display: inline-block;
  font-size: 0.80em;
  font-family: "Gotham";
  vertical-align: middle;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  border: none;
  text-transform: uppercase;
}

/** Bureau carte **/
div[data-url='#Bourges']
{
  top: 41%;
  left: 45%;
}

div[data-url='#Caen']
{
  top: 17%;
  left: 30%;
}

div[data-url='#Montpellier']
{
  top: 85%;
  left: 55%;
}

div[data-url='#La Rochelle']
{
  top: 46%;
  left: 27%;
}

/** taille des centaines de millions page index **/
#home-boxes>.box:nth-of-type(4)>div>div
{
  font-size: 1.8rem;
  line-height: initial;
}

/** Compétences **/
.competence:hover 
{
  z-index: 100;
}



/** FIN **/

// @font-face {
//   font-family: 'Gotham';
//   src: url('http://localhost:8888/wp-content/themes/add/webfonts/GothamLight.woff2') format('woff2'),
//       url('http://localhost:8888/wp-content/themes/add/webfonts/GothamLight.woff') format('woff');
//   font-weight: 300;
//   font-style: normal;
// }


@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 300;
    src: url("https://fonts.googleapis.com/css?family=Raleway:400,700,900");
}
@font-face {
    font-family: "Raleway";
    font-style: italic;
    font-weight: 300;
    src: url("https://fonts.googleapis.com/css?family=Raleway:400,700,900");
}



 
html {overflow-y: scroll !important;}
body {margin:0;background:#fff;line-height: 1.3;-moz-text-size-adjust:100%;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;}
body {font-family: 'Din', sans-serif;font-weight: 300;font-style: normal;font-size:16px;color:#000;}
.count-container, .inter-text > h5, .inter-links h4, #inter-top, #inter-menu, #video-l .title, #offices, #honoraires-menu, #nos_engagements, #persons-back, #person-info, #person-details h3, #person-details h4, #experts-text, #persons, #offer-form-over, #offer-more, #offers-list, .competence, .main-title, #submenu, #footer .menu, .custom_font, .highlight_font, #banner, #header {font-family: 'Helvetica', 'Helvetica';}
.main-title {font-weight: 300;}
select, td, input, textarea, button {font-size: inherit;font-family: inherit;color: inherit;}
input, textarea, button {outline:none;}
iframe {max-width: 100%;}
* {-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}
a {color:inherit;outline:none;text-decoration:none;}
#container, #logo img, #menu-btn, #menu-btn span, #header-logo, 
.is_csstrans #nos_engagements .box .arr-down, .is_csstrans #nos_engagements .box-content, #video-poster, #video-btn, .office .icon, .office-details,
#honoraires-content .box, #honoraires-content .box-text, #honoraires-menu .box, .experts-submore-links > div span, .experts-submore-links > div, #experts-blocks > div,
.person > div, input, select, .btn-close:before, .btn-close:after, .offer, .offer-btn, .competence, .competence > div, #left-img .img, #left-img-sw li, #submenu a:after,
#banner-select span, #menu ul ul, #banner-select ul, a {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
a:hover {color: #CF0A2C;}
.tran {
  -webkit-transition: -webkit-transform 0.4s ease-out 0s;
  transition: transform 0.4s ease-out 0s;  
}
a img {border:none;}
img {max-width:100%;height: auto;}
#map img {max-width:none;}
h1, h2, h3, h4, h5, h6, ul, ol, p {margin:0;padding: 0;}
strong, .custom_font strong, strong .custom_font, .highlight_font strong, strong .highlight_font {}
ul, ol {padding-left: 1.5em;}
.clr, .clearfix {float: none !important;clear: both !important;width: auto !important;height: auto !important;padding: 0 !important;margin: 0 !important;border:none !important;}
#content, .container {margin: 0 auto;max-width:1280px;width: auto;}
.highlight_font, .highlight {color:#CF0A2C;}
#logo {position: absolute;left: 50%;margin-left: -160px;top: 44px;}
#logo img {width: 320px;display: block;}
#header {min-height: 222px;padding-bottom: 44px;padding-top: 44px;position: relative;z-index: 99;}
#header-right {float: right;padding-top: 25px;}
#text-content, #home-main, #header .container {padding: 0 90px;}
#socials {overflow:hidden;margin-bottom: 12px;}
#socials a {display: block;float: right;line-height: 13px;margin-left: 10px;}
#socials a img {display: block;height: 13px;}
#langs {overflow: hidden;}
#langs ul {list-style: none;padding: 0;line-height: 1;font-size: 0.94em;text-transform: uppercase;float: right;}
#langs li {float: left;padding-left: 10px;margin-left: 10px;border-left: 1px solid #000;}
#langs li:first-child {padding: 0;margin: 0;border: none;}
#searchform label {display: none;}
#searchform #s {line-height: 20px;height: 22px;border: 1px solid #000;width: 130px;padding: 0 5px;margin-left: 6px;}
#searchsubmit {background: url("images/search.png") no-repeat center center; background-size: 20px auto;display: block;float: left;border: none;height: 22px;font-size: 0;width: 22px;padding:0;}
#header-menu {float: left;text-transform: uppercase;padding-top: 8px;}
#header-menu ul {list-style: none;margin: 0;padding: 0;font-size: 1.06em;font-weight: 300;}
#header-menu li {border-bottom:2px solid #a7a8aa;padding-bottom: 6px;margin-top: 6px;}
#header-menu li:first-child {margin-top:0;}
#header-menu ul a span {font-size: 1.17em;font-weight: 400;display: block;}
#menu {position:relative;z-index:99;padding-top: 76px;}
#submenu ul, #menu ul {display: table;margin: 0 auto;list-style: none;padding: 0;border-collapse: collapse;}
#submenu li, #menu li {float: left;border-left: 2px solid #000;margin-left: 15px;padding-left: 15px;position: relative;}
#submenu li:first-child, #menu li:first-child {margin: 0;padding: 0;border: none;}
#menu ul ul {visibility: hidden;opacity: 0;top:100%;position:absolute;padding: 18px 31px 0 0;min-width: 100%;display:block;}
#menu ul li:first-child ul {padding-right:0;}
#menu li:hover ul {visibility: visible;opacity: 1;}
#menu li.page-item-9 ul {display: none !important;}
#menu li li, #menu li li:first-child {float: none;padding:0 0 8px 0;border:none;margin:0;}
#menu li li a {display: block;background: #fff;text-align: center;padding: 5px 10px;}
.current_page_parent > a, .current_page_item > a, #langs a.lang_sel_sel {color: #CF0A2C;}
#banner {height: 349px;width: 100%;background-position: center center;background-repeat: no-repeat;background-size: cover;display: table;border-collapse: collapse;text-align: center;color: #CF0A2C;font-size: 2.18em;text-transform: uppercase;background-image: url("images/banner-2.jpg");}
.page-id-9 #banner {display: none !important;}
#banner-in {display: table-cell;vertical-align: middle; font: Helvetica;}
.home #banner {background-image: url("images/home.png");margin-bottom: 60px;} 
#home-box {overflow: hidden;background: #e3e3e3;}
#home-box ul {padding: 0;list-style: none;}
#grey_box_1, #grey_box_2 {padding: 38px;width: 50%;position: relative;float: left;}
#grey_box_1:after, #grey_box_2:after {content: "";width: 4px;height: 100%;position: absolute;top:0;background: #fff;}
#grey_box_1:after {left: 100%;margin-left: -2px;}
#grey_box_2:after {left: 0%;margin-left: -2px;}
.square:before {content:"■";position:relative;top:-2px;padding:0 4px;}
#home-box li {padding-left: 0.7em;position: relative;margin-bottom: 10px;}
#home-box li:before {position: absolute;left: 2px;top: 8px;width: 5px;height: 5px;display: block;background: #CF0A2C;content: "";}
.count-container {overflow:hidden;}
.count-container .box {width: 33%;float: left;margin-top: 60px;width: 33.3333%;}
.count-container .box img {height: 65px;display: block;margin-bottom: 17px;}
.count-container .box h4 {font-size: 1em;text-transform: uppercase;position: relative;margin-bottom: 9px;font-weight: 300;line-height: normal;padding-top: 21px;min-height: 75px;}
.count-container .box h4:after, .count-container .box h4:before, 
#inter-links h4:before, #inter-links h4:after, #inter-top .main-title:after, #inter-top .main-title:before, #nos_engagements h3:after, #nos_engagements h3:before {position: absolute;bottom: 0;height: 1px;content: "";}
.count-container .box h4:before, 
#inter-links h4:before, #inter-top .main-title:before, #nos_engagements h3:before {width: 190px;background: #CF0A2C;left:0;z-index: 1;}
.count-container .box h4:after, 
#inter-links h4:after, #inter-top .main-title:after, #nos_engagements h3:after {width: 6px;left: 27px;background: #fff;z-index: 2;}
.count-container .box div div {font-size: 2.93em;font-weight: 300;line-height: 61px;}
.count-container .box {text-align: center;}
.count-container .box > div {text-align: left;display: inline-block;width: 190px;}
#home-main {margin-bottom: 60px;}
#footer {background: #E3E3E3;padding: 19px 0;position: relative;overflow: hidden;color:#646469;margin-top: 60px;}
.footer_fixed #footer {position:fixed;left: 0;bottom: 0;width: 100%;-webkit-transform: translateZ(0px);transform: translateZ(0px);}
#footer-1 {overflow: hidden;min-height:44px;margin-bottom:10px;}
#iso-9001 {height: 54px;float:left;}
#logo2 {height: 44px;position: absolute;left: 50%;margin-left: -65px;top:23px;}
#logo2 img {height: 44px;}
#footer-list {list-style: none;display: table;padding: 0;margin: 0 auto;font-size: 0.69em;text-align: center;}
#footer-list li {margin-left: 3px;position: relative;display: inline-block;}
#footer-list li:before {width: 5px;height: 5px;display: inline-block;background: #CF0A2C;content: "";top:-1px;position:relative;margin-right:3px;}
#footer-list li:first-child:before {content:none;display:none;}
#footer-list li:first-child {padding:0;margin:0;}
#footer .container {padding-left:60px;padding-right:60px;}
#footer .menu {float: right;font-size: 0.63em;padding-top: 21px;text-transform: uppercase;}
#footer .menu ul {list-style: none;margin: 0;padding: 0;text-align: right;}
#footer-list {text-transform:uppercase;}
#banner-select {position: relative;display: table;margin: 25px auto 0 auto;}
#banner-select ul, #banner-select span {line-height: 48px;display: inline-block;color: #fff;font-size: 0.37em;font-weight: 300;width:310px;background: #CF0A2C;text-align: center;display: block;float: left;}
#banner-select span {cursor: pointer;}
#banner-select .btn {width: 50px;height: 48px;border: none;background-image: url("images/options.png"); background-repeat: no-repeat;background-position: center center; background-size: auto 11px;border-left: 2px solid #fff;}
#banner-select ul {background: none;padding: 0;position: absolute;top:100%;left: 0;list-style: none;line-height: 37px;font-weight: 400;letter-spacing: 1px;opacity: 0;visibility: hidden;}
#banner-select.clicked ul, #banner-select:hover ul {opacity: 1;visibility: visible;}
#banner-select li {margin-top: 6px;}
#banner-select li a {display: block;background: #CF0A2C;}
#banner-select li a:hover, #banner-select:hover span {background-color: #fff;color:#CF0A2C;}
#banner-select:hover .btn {background-image: url("images/options2.png");border-left-color: #CF0A2C;}

#intro {background: #e3e3e3;overflow: hidden;}
#intro #submenu {background: #fff;}
#left-img {width:35%;height: 360px;background: #CF0A2C;position: relative;float: right;}
#left-img .img {position: absolute;left: 0;top:0;height: 270px;width:100%;padding-top: 60px;opacity: 0;}
#left-img .img.active {opacity: 1;}
#left-img .img img {height: 210px;display: block;margin: 0 auto;}
#left-img-sw {position: absolute;left: 0;top: 312px;width: 100%;z-index: 2;}
.box-text-sw ul, #left-img-sw ul {display:table;margin:0 auto;list-style: none;padding: 0;}
.box-text-sw li, .separator span, #left-img-sw li {height: 10px;width: 10px;border-radius: 50%;margin: 0px 9px;background: #fff;-webkit-transform: scale(0.6);-ms-transform: scale(0.6);transform: scale(0.6);float: left;cursor: pointer;}
.box-text-sw li.active, .separator span:nth-child(2), #left-img-sw li:hover, #left-img-sw li.active {-webkit-transform: scale(1);-ms-transform: scale(1);transform: scale(1);}
#right-intro {width:53%;float: right;}
#right-intro .content {padding: 35px 68px 10px 68px;}
#submenu {padding-top: 37px;padding-bottom: 19px;}
#submenu a {position:relative;}
#submenu a:after {content:"";position:absolute;bottom:-18px;left:50%;width:0%;height:10px;background:#e3e3e3;}
#submenu a:hover:after, #submenu li.current_page_parent a:after, #submenu li.current_page_item a:after {left:0%;width:100%;}
.page-id-19 #intro{padding-bottom: 34px;}
#competences {position: relative;height: 1300px;padding-top: 50px;margin-bottom: 40px;}

.competence {display: table;width:288px;height:288px;border-radius: 50%;background-color: #e3e3e3;background-position: center center;background-repeat: no-repeat;color: #fff;position: absolute;left: 50%;margin-left: -144px;font-size: 0.94em;}
.is_csstrans .competence {opacity: 0;}
#competence-1 {background-image:url("images/c-1.png");background-size: auto 134px;margin-left: -363px;top: 400px;}
#competence-2 {background-image:url("images/c-2.png");background-size: auto 118px;margin-left: -76px;top: 518px;}
#competence-3 {background-image:url("images/c-3.png");background-size: auto 124px;width:256px;height: 256px;margin-left: -309px;top: 708px;}
#competence-4 {background-image:url("images/c-4.png");background-size: auto 104px;width:230px;height: 230px;margin-left: -46px;top: 841px;font-size: 0.69em;}
#competence-5 {background-image:url("images/c-5.png");background-size: auto 63px;width:182px;height: 182px;margin-left: -221px;top: 1006px;font-size: 0.69em;}
#competence-6 {background-image:url("images/c-6.png");background-size: auto 58px;width:182px;height: 182px;margin-left: 181px;top: 1006px;font-size: 0.69em;}
#competence-7 {background-image:url("images/c-7.png");background-size: auto 57px;width:182px;height: 182px;margin-left: -24px;top: 1117px;font-size: 0.69em;}
.competence > div {display: table-cell;vertical-align: middle;padding: 0 10px;-webkit-transform: scale(0.8);-ms-transform: scale(0.8);transform: scale(0.8);opacity: 0;text-align: center;text-transform: uppercase;}
.is_csstrans #competence-1, .is_csstrans #competence-3, .is_csstrans #competence-5 {
  -webkit-transform:translateX(-40px) scale(1.1);
  transform:translateX(-40px) scale(1.1);
}
.is_csstrans #competence-2, .is_csstrans #competence-4, .is_csstrans #competence-6 {
  -webkit-transform:translateX(40px) scale(1.1);
  transform:translateX(40px) scale(1.1);
}
.is_csstrans #competence-7 {
  -webkit-transform:translateY(40px) scale(1.1);
  transform:translateY(40px) scale(1.1);
}
.is_csstrans .competence.active {
  opacity: 1;
  -webkit-transform:translateX(0) translateY(0) scale(1) !important;
  transform:translateX(0) translateY(0) scale(1) !important;  
}
#competences .competence:hover {background-color:#CF0A2C;background-image: none;}
.competence:hover > div {-webkit-transform: scale(1);-ms-transform: scale(1);transform: scale(1);opacity: 1;}
#content h2, .main-title {font-size: 3.12em; color:#CF0A2C;text-align: center;text-transform: uppercase;letter-spacing: 3px;margin-bottom: 30px;position: relative;}
#content h2 {font-size:2.19em;margin-bottom: 0;}
#content .main-title {margin-bottom: 30px;}
.arr-down {background: url("images/arr-down.png") no-repeat center top;width: 23px;height: 175px;background-size: 23px auto;margin: 20px auto;}
#offer-form-over, #offers {margin:70px auto 0 auto;border:1px solid #e3e3e3;max-width: 800px;overflow:hidden;}
#offers {border-bottom:none;}
#offers-intro {text-align:center;padding:40px 20px 70px 20px;border-bottom:1px solid #e3e3e3;}
#offers-list {overflow:hidden;}
.offer {width:50%;float:left;border-bottom:1px solid #e3e3e3;padding: 23px 20px 13px 62px;font-size: 0.75em;}
.offer:nth-child(2n) {border-left:1px solid #e3e3e3;}
.offer-title {font-size:1.33em;text-transform:uppercase;margin-bottom:14px;}
.offer-more-btn {color:#CF0A2C;text-decoration:underline;cursor: pointer;}
.offer-intro {margin-bottom:14px;}
.offer-btn {width:90px;line-height:20px;height:20px;display:block;background:#e3e3e3;text-align:center;font-size:0.75em;color:#878787;text-transform:uppercase;cursor: pointer;}
.offer:hover {background:#000;color:#fff;}
.offer:hover .offer-btn {background:#fff;color:#000;}
#offer-more {overflow: hidden;font-size:0.75em;}
#offer-more .offer-more, #offer-more {display:none;}
.offer-more .offer-btn {float:right;background:#fff;color:#000;}
.offer-more {padding: 36px 80px 28px 80px;color:#fff;background:#CF0A2C;position: relative;overflow: hidden;}
.offer-more .offer-text {
  -moz-column-count: 2;
  -moz-column-gap: 40px;
  -ms-column-count: 2;
  -ms-column-gap: 40px;
  -webkit-column-count: 2;
  -webkit-column-gap: 40px;
  column-count: 2;
  column-gap: 20px;   
  margin-bottom: 6px;
}
.btn-close {width: 22px;height: 22px;display: block;position: absolute;right: 19px;top: 21px;border-radius: 50%;background: #fff;cursor: pointer;z-index: 3;}
.btn-close:before, .btn-close:after {content: "";width: 8px;height: 2px;position: absolute;left: 50%;top:50%;margin: -1px 0 0 -4px;display: block;background: #CF0A2C;}
.btn-close:hover:before {-webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);transform: rotate(45deg);}
.btn-close:hover:after {-webkit-transform: rotate(135deg);-ms-transform: rotate(135deg);transform: rotate(135deg);}
.page-id-21 .separator, #offer-form-over {border: none;margin-top:31px;}
#offer-form input, #offer-form select {height: 50px;border: 1px solid #e3e3e3;line-height: 50px;padding: 0 15px;font-size: 0.75em;color: #878787;}
#offer-form .customfile-wrap input {width:100%;}
#offer-form > div {overflow: hidden;margin-bottom: 10px;}
#offer-form > div > * {width: 48%;float: left;margin-left: 2%;}
#offer-form > div > *:first-child {margin-right: 2%;margin-left: 0;}
.form_cont div input, .form_cont div textarea, .form_cont div select {width: 100%;}
#offer-form .s_btn {display: block;background: #CF0A2C;line-height: 50px;text-align: center;font-size: 0.75em;color: #fff;cursor: pointer;width: 100%;float: none;margin: 0;text-transform:uppercase;}
.customfile-upload {padding: 0 !important;margin: 0 !important;line-height: 22px !important; height: 22px !important;width:22px !important;border: none !important;background: url("images/file-btn.png") no-repeat center center !important;position: absolute;right: 7px;top: 14px;font-size: 0;background-size: 22px auto !important;cursor: pointer;}
.err .customfile-upload {background-image: url("images/file-btn2.png") !important;}
.customfile-wrap {position: relative;}
#offer-re {display:none;background: #e3e3e3;text-align: center;padding: 30px 10px;}
.form_el {padding-bottom: 15px;}
.form_el.sending {background:url("images/sending.gif") no-repeat center top;}
.form_cont .err input, .form_cont .err select, .form_cont .err textarea {color: #fff !important;background: #CF0A2C !important;}
.separator {width: 100%;}
.separator > div {display: table;margin: 0 auto;}
.separator span {background: #878787;cursor: auto;}
#persons {overflow: hidden;position:relative;}
#persons-top {overflow:hidden;}
.person-block {position: relative;}
.person {background-position: top center;background-size: cover;background-repeat: no-repeat;position: relative;background-color: #ddd;width: 25%;}
.person-logo {background-position: center center;background-size: contain;background-color: transparent;border:10px solid transparent;}
.person {position:absolute;}
#persons-top .person {position:relative !important;top:auto !important;bottom:auto !important;left:auto !important;right:auto !important;float:left;}
.person-block:nth-child(2n+1) .person {top:0;}
.person-block:nth-child(2n) .person {bottom:0;}
.person:nth-child(4n+2) {left:25%;}
.person:nth-child(4n+3) {left:50%;}
.person:nth-child(4n) {left:75%;}
.person:first-child {position:relative;}
.person:after {content: "";display: block;padding-top: 75%;}
#persons-top .person:after, .person-block:nth-child(2n) .person:nth-child(4n):after, .person-block:nth-child(2n) .person:nth-child(4n+2):after, .person-block:nth-child(2n+1) .person:nth-child(4n+1):after, .person-block:nth-child(2n+1) .person:nth-child(4n+3):after {padding-top: 125%;}
.person.hidden {opacity: 0;visibility: hidden;}
.person strong, .person em {display: block;text-transform: uppercase;}
.person strong {font-size: 1.31em;}
.person > div {position: absolute;left: 0;top:0;width: 100%;height: 100%;opacity: 0;background: #CF0A2C;background: rgba(207,10,44,0.9);}
.person > div > div {display: table;border-collapse: collapse;position: absolute;left: 0;top:0;width: 100%;height: 100%;}
.person > div > div > div {vertical-align: middle;text-align: center;padding: 0 10px;color: #fff;display: table-cell;}
.person:hover > div {opacity: 1;}
.person a {position: absolute;left: 0;top:0;width: 100%;height: 100%;z-index: 2;}
.person span {display: block;width: 22px;height: 22px;background: #fff;border-radius: 50%;position: relative;margin: 30px auto 0 auto;}
.person span:after, .person span:before {position: absolute;left: 50%;top:50%;width: 12px;height: 2px;margin:-1px 0 0 -6px;background: #CF0A2C;content: "";}
.person span:after {-webkit-transform: rotate(100deg);-ms-transform: rotate(100deg);transform: rotate(100deg);}
#experts-numbers > div:nth-child(3n+1) {clear: left;}
.experts-submore, #experts-more {display: none;}
#experts-more > div {display: none;}
#experts-text {max-width: 800px;margin: 0 auto;overflow: hidden;}
#experts-text .intro-text {text-transform:uppercase;margin-bottom: 70px;text-align: center;padding: 0 20px;}
#experts-more, #experts-blocks {overflow: hidden;width: 600px;margin: 0 auto;}
#experts-blocks > div {width: 50%;height: 300px;float: left;background: #e3e3e3;position: relative;border-left: 1px solid #fff;cursor:pointer;}
#experts-blocks > div:first-child {border-left: 0;}
#experts-blocks > div > div {display: table;border-collapse: collapse;width: 100%;height: 100%;left: 0;top:0;position: absolute;}
#experts-blocks > div > div > div {display: table-cell;vertical-align: middle;text-align: center;font-size: 1.37em;text-transform: uppercase;}
#experts-blocks > div span {display: block;background-repeat: no-repeat;background-position: center top;background-size: auto 51px;padding: 75px 10px 0 10px;}
#experts-blocks > div:first-child span {background-image:url("images/block-1.png");}
#experts-blocks > div:nth-child(2) span {background-image:url("images/block-2.png");}
#experts-more > div:first-child .experts-more .icon, #experts-blocks > div:first-child:hover span {background-image:url("images/block-1a.png");}
#experts-more > div:nth-child(2) .experts-more .icon, #experts-blocks > div:nth-child(2):hover span {background-image:url("images/block-2a.png");}
#experts-blocks > div:hover {color: #fff;background: #CF0A2C;}
#experts-more > div {display:none;position: relative;}
#experts-more > div.active {display:block;}
.experts-submore-text {position: relative;display: none;}
.experts-submore-text, .experts-more {color:#fff;background-color:#CF0A2C;position: relative;padding: 37px;text-align: center;margin-bottom: 17px;}
.experts-submore-text .icon, .experts-more .icon {display: block;height: 51px;margin-bottom: 10px;background-position: center bottom;background-size: auto 100%;background-repeat:no-repeat;}
.experts-submore-text *:first-child strong, .experts-more *:first-child strong {font-size:1.75em;font-weight: inherit;text-transform: uppercase;}
.experts-submore-links {overflow: hidden;}
.experts-submore-links > div {width: 25%;float: left;border-left: 3px solid #fff;background: #e3e3e3;height: 160px;position: relative;font-size: 0.81em;text-transform: uppercase;text-align: center;padding-top:46px;cursor:pointer;}
.experts-submore-links > div:first-child {border-left:none;}
.experts-submore-links > div span {display: block;height: 34px;background-size: auto 34px;background-position: center center;background-repeat: no-repeat;margin-bottom:16px;}
.experts-submore-links > div:nth-child(1) span {background-image: url("images/subi-1.png"); background-size:auto 28px;}
.experts-submore-links > div:nth-child(2) span {background-image: url("images/subi-2.png");}
.experts-submore-links > div:nth-child(3) span {background-image: url("images/subi-3.png");}
.experts-submore-links > div:nth-child(4) span {background-image: url("images/subi-4.png");}
.experts-submore-links > div:nth-child(1).active span, .experts-submore-links > div:nth-child(1):hover span {background-image: url("images/subi-1a.png");;}
.experts-submore-links > div:nth-child(2).active span, .experts-submore-links > div:nth-child(2):hover span {background-image: url("images/subi-2a.png");}
.experts-submore-links > div:nth-child(3).active span, .experts-submore-links > div:nth-child(3):hover span {background-image: url("images/subi-3a.png");}
.experts-submore-links > div:nth-child(4).active span, .experts-submore-links > div:nth-child(4):hover span {background-image: url("images/subi-4a.png");}
.experts-submore-links > div:hover, .experts-submore-links > div.active {background:#646569;color:#fff;}
.experts-submore {background-size: auto 68px;background-position: center 30px;background-repeat: no-repeat;}
.experts-submore > div:nth-child(1) .icon {background-image: url("images/subi-1a.png");;}
.experts-submore > div:nth-child(2) .icon {background-image: url("images/subi-2a.png");}
.experts-submore > div:nth-child(3) .icon {background-image: url("images/subi-3a.png");}
.experts-submore > div:nth-child(4) .icon {background-image: url("images/subi-4a.png");}
#video, #person {display: table;border-collapse: collapse;width: 100%;height: 486px;}
#video > div, #person > div {display:table-cell;vertical-align: middle;}
#video-l, #person-details {background: #CF0A2C;background-position: center center;background-repeat: no-repeat;background-size: cover;color:#fff;padding: 43px 65px 10px 65px;vertical-align: top !important;}
#video-l {padding-bottom: 43px;}
#person-image {background-position:center center;background-repeat: no-repeat;background-size: cover;width: 67%;}
#person-details a img {height:12px;}
#video-l .title, #person-details h3 {font-size: 1em;text-align: center;text-transform: uppercase;position: relative;border-bottom: 1px solid #f7bfc8;padding-bottom: 14px;margin-bottom: 42px;}
#video-l .title:after, #person-details h3:after {content: "";background: #CF0A2C;height: 1px;width: 12px;position: absolute;top:100%;left: 50px;}
#video-l .scrolled-div {height: 400px;}
#person-details h4 {font-size: 0.87em;text-transform: uppercase;position: relative;padding-bottom: 8px;margin-bottom: 11px;}
#person-details h4:after {content: "";background: #f7bfc8;height: 1px;width: 50px;position: absolute;top:100%;left: 0;}
#person-details a:hover {color: inherit;}
#person-details > div {border-bottom: 1px solid #f7bfc8;padding-bottom: 19px;margin-bottom: 17px;}
#person-info {text-align:right;padding-right:54%;font-size:1.44em;color:#646469;padding-left:2%;}
#person-info h2 {font-size:1.65em;color:#383838;text-transform:uppercase;text-align: inherit;}
#person-info h3 {font-weight:inherit;text-transform:uppercase;}
#person-info div {margin-top:30px;}
#persons-back {font-size:0.69em;display: inline-block;margin: 0 0 20px 66px;text-transform: uppercase;}
#nos_engagements {text-align: center;text-transform: uppercase;}
#nos_engagements {max-width: 1000px;margin: 0 auto;line-height: 1.4;padding-top: 37px;}
#nos_engagements p {margin-bottom:8px}
#nos_engagements .box {max-width: 400px;margin: 0 auto;font-size: 1em;padding: 0 20px;}
.is_csstrans #nos_engagements .box .box-content {
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;  
}
.is_csstrans #nos_engagements .box:nth-child(4n) .arr-down {
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.is_csstrans #nos_engagements .box:nth-child(4n) .box-content {
  -webkit-transition-delay: 0.6s;
  -moz-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;  
}
.is_csstrans #nos_engagements .box .box-content {
  opacity: 0;
  -webkit-transform:translateY(-30px) scale(1.1);
  transform:translateY(-30px) scale(1.1);
}
.is_csstrans #nos_engagements .box:first-child .box-content, .is_csstrans #nos_engagements .box.active .box-content {
  opacity: 1;
  -webkit-transform:translateY(0) scale(1) !important;
  transform:translateY(0) scale(1) !important;  
}
.is_csstrans #nos_engagements .box .arr-down {background-position: 0 -175px;}
.is_csstrans #nos_engagements .box:first-child .arr-down, .is_csstrans #nos_engagements .box.active .arr-down {background-position: 0 0;}
#nos_engagements h3 {font-size: 1.62em;font-weight: inherit;position: relative;margin-bottom: 20px;padding-bottom: 17px;letter-spacing: 1px;margin-top: 28px;}
#nos_engagements h3:before {background: #4f4f4f;}
#nos_engagements h3:before {width: 200px;left: 50%;margin-left: -100px;}
#nos_engagements h3:after {width: 14px;left: 50%;margin-left: -40px;}
#nos_engagements h4 {font-size: 1.12em;color:#CF0A2C;position: relative;margin-bottom: 10px;padding-bottom: 14px;}
#nos_engagements h4:after {background: #CF0A2C;width:10px;height: 1px;position: absolute;content: "";left: 50%;margin-left: -5px;bottom:0;}
#nos_engagements .box:nth-child(4n+2) {float: left;}
#nos_engagements .box:nth-child(4n+3) {float: right;}
#nos_engagements .arr-down {margin-top: -100px;}
#nos_engagements .box:first-child .arr-down {margin-top: 0px;}
#nos_engagements .box a {font-size: 0.81em;}
#submenu-over {overflow: hidden;padding-bottom: 62px;}
#submenu-over.rev {background: #e3e3e3;}
#submenu-over #submenu {background: #e3e3e3; float: right;padding-left: 100px;padding-right: 100px;}
#submenu-over #submenu a:after {background: #fff;}
#submenu-over.rev #submenu {background: #fff;}
#submenu-over.rev #submenu a:after {background: #e3e3e3;}
.page-id-23 .main-title {font-size:1.87em;letter-spacing: 2px;}
.page-id-25 .arr-down {margin-top: 0;}
.page-id-31 #text, .page-id-25 #text, .page-id-27 #text {text-align: center;}
.page-id-25 #submenu-over {padding-bottom: 0;}
.page-id-49 #text-in {padding: 30px 20px;}
#honoraires {background: #CF0A2C;overflow: hidden;}
#honoraires > * {height: 370px;}
#honoraires-menu {width: 30%;float: left;}
#honoraires-menu .box {height: 185px;background:#fff;cursor: pointer;text-align: center;text-transform: uppercase;font-weight: 300;padding: 36px 10px 0 10px;}
#honoraires-menu .box .icon {background-repeat: no-repeat;background-position: center center;background-size: auto 100%;display: block;margin-bottom: 10px;height:57px;}
#honoraires-menu .box:nth-child(1) .icon {background-image: url("images/honoraires-1.png");}
#honoraires-menu .box:nth-child(2) .icon {background-image: url("images/honoraires-2.png");}
#honoraires-menu .box:hover, #honoraires-menu .box.active {background: #000;color: #fff;}
#honoraires-content {width: 70%;float: right;position: relative;color: #fff;}
#honoraires-content .box-text {padding: 50px 60px 40px 60px;}
#honoraires-content .box-text > div {overflow: hidden;}
#honoraires-content .box-text, #honoraires-content .box {position: absolute;left: 0;width: 100%;top:0;height: 100%;opacity: 0;visibility: hidden;}
#honoraires-content .box-text.active, #honoraires-content .box.active {opacity: 1;visibility: visible;}
.box-text-sw {position: absolute;left: 0;width: 100%;bottom: 15px;z-index: 2;}
#offices {background-repeat: no-repeat; background-position: center top;background-size: 100% auto;margin: 0 auto;position: relative;}
#offices img {display:block;}
.page-id-127 #offices {max-width:912px;}
.page-id-129 #offices {max-width:1276px;}

.office {position: absolute;text-align:center;}
.office .icon {height: 84px;background:url("images/marker.png") no-repeat center top; background-size: auto 100%;display: block;cursor: pointer;-webkit-transform-origin:50% 100%;-ms-transform-origin:50% 100%;transform-origin:50% 100%;width: 54px;position: absolute;left: -27px;top: -84px;}
.office-name {font-weight: 300;text-transform: uppercase;letter-spacing: 1px;position: absolute;left: 0;top: 5px;}
.office-name > span {display:block;position:relative;left:-50%;background: #fff;padding: 2px 6px;border-radius: 4px;cursor: pointer;}
.office-name span span {display:block;font-size:0.75em;font-weight:400;}
.office-details {opacity: 0;visibility:hidden;position: absolute;background: #CF0A2C;color: #fff;left: 50%;width: 340px;padding: 12px 10px;margin-left: -170px;top: 5px;font-size: 0.68em}
.office-details hr {display: block;position: relative;border:none;background: #fff;height: 1px;width: 84%;margin-top:0;margin-left:auto;margin-right:auto;}
.office-details hr:after {content: "";position: absolute;left: 50px;top:0;background: #CF0A2C;height: 1px;width: 10px;}
.office-details h4 {font-size: 1.47em;font-weight: inherit;text-transform: uppercase;letter-spacing: 1px;margin-bottom:0;}
.office-details h4 + a, .office-details h4 + hr {margin-top:10px;}
.office-details > * {margin-bottom:10px;}
.office-details a {display:inline-block;}
.office-details h5 {font-size: 1.19em;font-weight: inherit;font-style: italic;text-transform: uppercase;}
.office-details a:hover {color: #fff;text-decoration: underline;}

.office.active {z-index:2;}
.office.active .icon {-webkit-transform:scale(1.1);-ms-transform:scale(1.1);transform:scale(1.1);}
.office.active .office-details {opacity: 1;visibility: visible;}
#office-134 {left: 50%;top: 19%;}
#office-134 .icon {height:108px;width: 70px;left: -35px;top: -108px;}
#office-134 .office-name {font-size:1.25em;}
#office-137 {left: 38%;top: 17%;}
#office-139 {left: 19%;top: 23%;}
#office-141 {left: 86%;top: 30%;}
#office-143 {left: 19%;top: 40%;}
#office-146 {left: 62%;top: 39%;}
#office-148 {left: 24%;top: 62%;}
#office-150 {left: 50%;top: 55%;}
#office-150 .office-name > span {max-width: 120px;}
#office-152 {left: 62%;top: 56%;}
#office-154 {left: 17%;top: 79%;}
#office-156 {left: 37%;top: 86%;}
#office-158 {left: 73%;top: 90%;white-space:nowrap;}
#office-160 {left: 82%;top: 82%;}
#office-162 {left: 44%;top: 18%;}
#office-164 {left: 50%;top: 22%;}
#office-166 {left: 39%;top: 26%;}
#office-168 {left: 40%;top: 40%;}
#office-170 {left: 86%;top: 40%;}
#office-170 .office-name {white-space:nowrap;}
#office-170 .office-details {margin-left: -207px;}
#office-168 .icon {background-image:url("images/marker-rev.png");position:absolute;-webkit-transform-origin:50% 0%;-ms-transform-origin:50% 0%;transform-origin:50% 0%;top: 0;}
#office-168 .office-name {top: auto;bottom: 5px;}
#office-168 .office-details {margin-top: -92px;}
.office-details .btn-close {width:14px;height:14px;right:10px;top:10px;}
.office-details .btn-close:after, .office-details .btn-close:before {width: 6px;margin: 0 0 0 -3px;height: 1px;}
.page-id-175 #video-l {background-image:url("images/video-bg.jpg");}
#video-r {position: relative;width: 67%;}
#video-r iframe {width:864px;height: 486px;display: block;}
#video-poster {position: absolute;left: 0;top:0;width: 100%;height: 100%;background-position: center center;background-repeat: no-repeat;background-size: cover;z-index: 2;visibility: visible;opacity: 1;}
.play #video-poster {visibility: hidden;opacity: 0;}
#video-btn {width:45px;height: 92px;left:50%;top:50%;margin:-46px 0 0 -23px;cursor: pointer;background:url("images/video-btn-b2.png") no-repeat center center;background-size:100% 100%;display: block;position: absolute;}

.inter-text {display:none;}
.inter-text:first-child {display: block;}
#inter-top {text-align:center;background: #e3e3e3;padding-bottom: 30px;}
#inter-top .main-title {font-size:1.44em;letter-spacing:1px;position:relative;padding-bottom: 16px;margin-bottom: 25px;}
#inter-top .main-title:after {left:50%;width:15px;margin-left:-120px;background: #e3e3e3;}
#inter-top .main-title:before {left:50%;width: 340px;margin-left:-170px;}
#inter-top .main-text {text-transform:uppercase;max-width: 720px;margin:0 auto;font-weight: 300;}
#interventions {overflow:hidden;text-transform:uppercase;display: table;width: 100%;border-collapse: collapse;height: 369px;}
#interventions > * {display: table-cell;vertical-align: middle;}
#inter-menu {width: 30%;font-weight: 300;}
#inter-menu ul {list-style:none;padding:0;}
#inter-menu li {float:left;width:50%;text-align:center;height: 96px;border-bottom: 2px solid #e4e4e4;border-left: 2px solid #e4e4e4;}
#inter-menu li:first-child {width: 100%;height: 81px;}
#inter-menu a {display:block;height:100%;padding: 55px 7px 0 7px;background-color:#fff;background-position: center 18px;background-size: auto 29px;background-repeat:no-repeat;line-height: 1.1;}
#inter-menu .current_page_item a, #inter-menu a:hover {background-color:#000;color:#fff;}

#inter-image {width: 40%;}
#inter-links {color:#fff;background:#CF0A2C;padding: 43px 75px 10px 75px;font-size: 0.94em;}
#inter-links ul {margin-top: 28px;}
#inter-links li {margin-top:26px;cursor:pointer;}
#inter-links li:hover, #inter-links li.active {text-decoration:underline;}
#inter-links li:first-child {margin-top:0;}
#inter-links h4 {position: relative;text-align: center;padding-bottom: 14px;}
#inter-links h4:before {background:#fff;width: 100%;}
#inter-links h4:after {background:#CF0A2C;}
.inter-text > h5 {font-size:1.12em;text-transform:uppercase;text-align:center;padding-bottom: 23px;border-bottom: 1px solid #000;margin-bottom: 28px;}
#inter-content {margin: 94px auto 0 auto;max-width: 860px;}
.inter-text {overflow:hidden;}
.inter-l {width:50%;float:left;position:relative;padding: 12px 74px;}
.inter-r {width:50%;float:right;position:relative;padding: 22px 0 22px 42px;}
.inter-l:after, .inter-r:after {position:absolute;display:block;content:"";width:1px;height:100%;background:#000;top:0;}
.inter-l:after {right:-1px;}
.inter-r:after {left:0;}
#container.loading:after {content: "";position: fixed;left: 0;top:0;width: 100%;height: 100%;z-index: 100;background: #fff url("images/ajax-loader.gif") no-repeat center center;opacity: 0.9;-webkit-transform: translateZ(0px);transform: translateZ(0px);}
img.aligncenter {margin: 0 auto; display: block;}
.count > span > span {display: none;text-align: right;}
.count > span > span:last-child {display: inline;}
.count {overflow:hidden;height: 61px;display: block;float: left;}
.csstransitions .count > span {margin-top: 61px;display: block;}
.csstransitions .count-container .box h4, .csstransitions .count-container .box img {
  -webkit-transform: scale(0.8) translateX(-50px);transform: scale(0.8) translateX(-50px);opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.csstransitions .count-container .count h4 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;  
}
.csstransitions .start-count .box h4, .csstransitions .start-count .box img {-webkit-transform: scale(1) translateX(0);transform: scale(1) translateX(0);opacity: 1;}
.csstransitions .count > span > span:last-child, .csstransitions .count > span > span {display: block;white-space: nowrap;}
.csstransitions .count > span {
  -webkit-transform:translateY(0%);
  transform:translateY(0%);
  -webkit-transition: all 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transition: all 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}
.csstransitions .count-container .box:nth-child(1) img {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.csstransitions .count-container .box:nth-child(1) h4 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.csstransitions .count-container .box:nth-child(1) .count > span {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.csstransitions .count-container .box:nth-child(2) img {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.csstransitions .count-container .box:nth-child(2) h4 {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.csstransitions .count-container .box:nth-child(2) .count > span {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

.csstransitions .count-container .box:nth-child(3) img {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}
.csstransitions .count-container .box:nth-child(3) h4 {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.csstransitions .count-container .box:nth-child(3) .count > span {
  -webkit-transition-delay: 1.8s;
  transition-delay: 1.8s;
}

.csstransitions .count-container .box:nth-child(4) img {
  -webkit-transition-delay: 1.8s;
  transition-delay: 1.8s;
}
.csstransitions .count-container .box:nth-child(4) h4 {
  -webkit-transition-delay: 2.1s;
  transition-delay: 2.1s;
}

.csstransitions .count-container .box:nth-child(4) .count > span {
  -webkit-transition-delay: 2.4s;
  transition-delay: 2.4s;
}

.csstransitions .count-container .box:nth-child(5) img {
  -webkit-transition-delay: 2.4s;
  transition-delay: 2.4s;
}
.csstransitions .count-container .box:nth-child(5) h4 {
  -webkit-transition-delay: 2.7s;
  transition-delay: 2.7s;
}

.csstransitions .count-container .box:nth-child(5) .count > span {
  -webkit-transition-delay: 3s;
  transition-delay: 3s;
}

.csstransitions .count-container .box:nth-child(6) img {
  -webkit-transition-delay: 3s;
  transition-delay: 3s;
}
.csstransitions .count-container .box:nth-child(6) h4 {
  -webkit-transition-delay: 3.3s;
  transition-delay: 3.3s;
}

.csstransitions .count-container .box:nth-child(6) .count > span {
  -webkit-transition-delay: 3.6s;
  transition-delay: 3.6s;
}

.csstransitions .start-count .count > span {
  -webkit-transform:translateY(-100%);
  transform:translateY(-100%);  
}
/*tmp*/
.page-item-179, .page-item-181, .page-item-33 {position: relative;}
.page-item-179:after, .page-item-181:after, .page-item-33:after {position: absolute;left: 0;top:0;width: 100%;height: 105%;background: transparent;content: "";display: block;z-index: 10;}
.page-item-179 a, .page-item-181 a, .page-item-33 a {opacity: 0.5;display: inline-block;}
.home-js #loading {
  background:#fff url("images/sending.gif") no-repeat center center;position: fixed;left: 0;top:0;width: 100%;height: 100%;z-index: 9999;visibility: visible;opacity: 1;
  -webkit-transition: all 0.6s ease-out 0s;
  transition: all 0.6s ease-out 0s;
  -webkit-transform: translateZ(0px);transform: translateZ(0px);
}
.home-loaded #loading {visibility: hidden;opacity: 0;}
.home-js #header, .home-js #menu, .home-js #content {
  opacity: 0;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-transition: all 0.3s ease-out 0.6s;
  transition: all 0.3s ease-out 0.6s;
}
.home-js #menu {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}
.home-js #content {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}
.home-loaded #header, .home-loaded #menu, .home-loaded #content {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
}
.at-sign {font-family: verdana, sans-serif !important;}
#persons-search {display: table;margin:0 auto;padding: 40px 0 0 0;position:relative;z-index:2;}
#persons-search .block {float: left;line-height: 24px;padding-left:80px;}
#persons-search .block:first-child {text-transform:uppercase;padding-left:0;}
#persons-search .label {display:inline-block;float: left;font-style: italic;text-transform: uppercase;margin-bottom: 20px;}
#persons-search .select {position: relative;display: inline-block;min-width:140px;float: left;margin-left: 20px;}
#persons-search .selected {border:1px solid #000;display: inline-block;width:100%;height:24px;line-height:24px;border-radius: 12px;cursor: pointer;padding: 0 38px 0 24px;font-size: 0.75em;overflow:hidden;background:url("images/lupa.png") no-repeat 5px 3px;position:relative;float: right;}
#persons-search .selected:after {content:"ok";position:absolute;right:-1px;top:-1px;width:24px;height:22px;border:1px solid #000;border-radius:50%;text-align:center;text-transform:uppercase;font-size:0.83em;line-height:24px;}
#persons-search .selected:before {content:"";position:absolute;right:28px;top:8px;width:7px;height:7px;background: url("images/triangle-d.png") no-repeat 0 0;}
#persons-search .select.active ul {display: block;z-index: 3;}
#persons-search ul {position: absolute;left: 0;top: 30px;list-style: none;padding: 10px;min-width:100%;background-color: #fff;background-color: rgba(255,255,255,0.96);display: none;border:1px solid #000;border-radius:12px;}
#persons-search li {white-space:nowrap}
#persons-search li a {display: block;}
#persons-search .option-list li {cursor:pointer;white-space:nowrap}
#persons-search li a:hover, #persons-search .option-list li:hover {text-decoration:underline;color: inherit;}
.faq {width: 25%;float: left;background: #CF0A2C;color: #fff;position: relative;box-shadow: inset 0 0 16px #b30824;cursor: pointer;}
.faq-pdf {background:#e3e3e3;box-shadow: inset 0 0 16px #808080;color:#000;font-size:1.6em;font-weight:bold;}
.faq:after {padding-top: 100%;display: block;content: "";}
.faq-pdf > a, .faq > strong, .faq > span {text-align: center;display: block;position: absolute;width: 100%;padding: 0 15px;}
.faq-pdf > a {padding:0;display: block;height: 100%;}
.faq-pdf > a > span {display: table;position: absolute; left: 0; top:0; width: 100%;height: 100%;}
.faq-pdf > a > span > span {display:table-cell;vertical-align:middle;padding: 0 15px;}
.faq > strong {font-size: 6.4em;line-height: 1;bottom: 40%;}
.faq > span {top: 65%;text-transform: uppercase;font-size: 0.75em;}
.faq > strong:after {position: absolute;bottom:0%;left: 38%;width: 24%;content: "";border-top: 1px solid #fff;}
.faq > .faq-answer {display: none;position: absolute;left: 0; top:0;width: 100%;min-height: 100%;background:#fff;color: #646569;font-size: 0.75em;padding: 40px 20px 20px 70px;}
.faq > .faq-answer h4 {color:#CF0A2C;font-weight:700;position:relative;margin-bottom:1em;text-transform: uppercase;}
.faq > .faq-answer h4 span {position:absolute;right:100%;bottom:0;font-size:4em;line-height:1;margin-bottom:-0.18em;margin-right: 10px;text-decoration: none;}
.faq > .faq-answer h4 strong {text-decoration:underline;}
.faq.row2 > .faq-answer {width: 200%;}
.faq.row2:nth-child(4n) > .faq-answer {left: auto;right: 0;}
.faq.active {z-index: 2;}
.faq.active > .faq-answer {display: block !important;height: 100%;padding:0 !important;}
.faq.active > .faq-answer > div {position: absolute;left: 0;top:0;width: 100%;height: 100%;display: table;background: #fff;}
.faq.active > .faq-answer > div > div {display: table-cell;vertical-align: middle;padding: 40px 20px 20px 70px;}
#message_ok, #message_err {display: none;}
@media (max-width:1260px) {
  #submenu li, #menu li {padding-left: 8px;margin-left: 8px;}
  #submenu, #menu {font-size: 0.88em;}
}
@media (min-width:1025px) {
  .office .icon:hover {-webkit-transform:scale(1.1);-ms-transform:scale(1.1);transform:scale(1.1);}
  .faq:hover {z-index: 2;}
  .faq:hover > .faq-answer {display: block !important;height: 100%;padding: 0 !important;}
  .faq:hover > .faq-answer > div {position: absolute;left: 0;top:0;width: 100%;height: 100%;display: table;background: #fff;}
  .faq:hover > .faq-answer > div > div {display: table-cell;vertical-align: middle;padding: 40px 20px 20px 70px;}
  #video-l {padding-right:45px}
  #video-l .scrolled-div.mCustomScrollbar.mCS_no_scrollbar, #video-l .scrolled-div {padding-right: 20px;}
  #video-l .scrolled-div.mCustomScrollbar {padding-right: 0;}
}
@media (max-width:1024px) {
  #menu ul ul {display: none !important;}
  .office .icon {width:46px;height:70px;left:-23px;top:-70px;}
  #office-134 .icon {height:90px;width: 60px;left: -30px;top: -90px;}
  #honoraires-content .box-text, .inter-r, .inter-l, #inter-links, #video-l, #person-details, #right-intro .content {padding-left: 30px;padding-right: 30px;}
  #persons-search .block {padding-left:60px;}
  .office-name > span > span {display:none;}
  .main-parent-11 #banner {background-position:right center;}
  #banner-select:hover ul {display: none;}  
  #banner-select.clicked ul {display: block;}
  #banner-select li a:hover, #banner-select:hover span {background-color: #CF0A2C;color: #fff;}
  #banner-select.clicked li a, #banner-select.clicked span {background-color: #fff;color:#CF0A2C;}
  #banner-select:hover .btn {background-image: url("images/options.png");border-left-color: #fff;}
  #banner-select.clicked .btn {background-image: url("images/options2.png");border-left-color: #CF0A2C;}
}
@media (max-width:1023px) {
  body {font-size:14px;min-width:767px}
  #footer .container, #text-content, #home-main, #header .container {padding-left:20px;padding-right: 20px;}
  #right-intro {width: 68%;}
  #left-img {width: 32%;}
  #logo {margin-left: -117px;top: 40px;}
  #logo img {width: 234px;}
  #header-menu {padding-top:0;}
  #header-right {padding-top:8px;}
  #menu {padding-top: 40px;}
  #header {min-height:186px;}
  .count-container .box div div {font-size: 2.5em;}
  #honoraires-content .box-text, .inter-r, .inter-l, #inter-links, #video-l, #person-details, #right-intro .content {padding-left: 20px;padding-right: 20px;}
  .block {padding-left:20px;}
  #persons-search .select {margin-left:10px;width: 120px;min-width: 0;}
  #experts-more, #experts-blocks {width:auto;}
  #inter-menu {width:40%;}
  #inter-image {width:30%;}
  #submenu, #menu {font-size: 0.8em;}
  .experts-submore-text, .experts-more, .offer-more, .offer {padding: 20px;}
  #office-170 .office-details {margin-left: auto;left: auto;right: -30px;}
  #inter-links {padding-top:0;padding-bottom:0;}
  #persons {font-size:0.8em;}
  .person span {margin-top: 10px;}
  .faq > strong {font-size: 5em;bottom: 46%;}
  .faq > span {top: 60%;}
}
@media (max-width:840px) {
  #offer-form-over, #offers {margin-left:20px;margin-right:20px;}
}
@media (max-width:599px) {
  body {min-width:320px;}
  #submenu, #menu {font-size:1em;}
  #submenu {font-size: 1em;}
  .home-js #header, .home-js #menu, .home-js #content {-webkit-transform:none !important;transform:none !important;}
  #header {padding:0;position: static;min-height: 0;}
  #header-logo {position: fixed;left: 0;top:0;width: 100%;padding:20px;background:#fff;z-index: 20;overflow: hidden;-webkit-transform: translateZ(0px);transform: translateZ(0px);}
  #header .container {display: none;position:fixed;left:0;width:100%;height:100%;padding: 70px 20px 20px 20px;background:#e3e3e3;top: 0;z-index: 19;overflow: auto;-webkit-transform: translateZ(0px);transform: translateZ(0px);}
  #header-right, #header-menu {float:none;text-align: center;}
  #header-right .searchform, #langs ul, #socials, #header-menu ul {display:table;margin:20px auto 0 auto;float:none;}
  #menu ul {display: block;}
  #menu li a {padding: 8px 0 !important;display:inline-block;}
  #menu li {float: none;padding-left: 0;margin-left: 0;border: none;}
  #menu li li {padding-bottom: 0 !important;margin-left:10px !important;}
  #menu li li a {background:none;text-align:left;padding-left:16px !important;position:relative;}
  #menu li li a:after {content:"";width:10px;height:10px;background:#cf0a2c;display:block;position:absolute;left:0;top:50%;margin-top:-5px;}
  #menu ul ul {display: block !important;position: static;padding: 0;min-width: 0;top: auto;opacity: 1;visibility: visible;}
  #logo {position:static;display: block;float: left;top: auto;margin: 0;}
  #menu-btn {width: 49px;padding:14px 9px;background:#cf0a2c;display:block;float:right;margin: 13px 0 0 0;}
  #menu-btn span {display:block;width:100%;height: 2px;background:#fff;margin-top:6px;}
  #menu-btn span:first-child {margin-top:0 !important;;}
  #logo img {width: 180px;}
  #container {padding-top: 115px;}
  
  .scrolled #logo img, .show-menu #logo img {width: 93px;}
  .scrolled #menu-btn, .show-menu #menu-btn {margin-top:0;}
  .scrolled #header-logo, .show-menu #header-logo {padding-top:10px;padding-bottom:10px;}
  .scrolled #menu-btn, .show-menu #menu-btn {width:40px;padding-top: 10px;padding-bottom: 10px;}
  .scrolled #menu-btn span, .show-menu #menu-btn span {margin-top:5px;}
  .show-menu #menu-btn span:nth-child(1) {-webkit-transform-origin:50% 50%;-ms-transform-origin:50% 50%;transform-origin:50% 50%;-webkit-transform:translateX(0px) translateY(7px) rotate(45deg);-ms-transform:translateX(0px) translateY(7px) rotate(45deg);transform:translateX(0px) translateY(7px) rotate(45deg);}
  .show-menu #menu-btn span:nth-child(2) {opacity:0;}
  .show-menu #menu-btn span:nth-child(3) {-webkit-transform-origin:50% 50%;-ms-transform-origin:50% 50%;transform-origin:50% 50%;-webkit-transform:translateX(0px) translateY(-7px) rotate(-45deg);-ms-transform:translateX(0px) translateY(-7px) rotate(-45deg);transform:translateX(0px) translateY(-7px) rotate(-45deg);}
  .show-menu #header .container {display:block;}
  #searchform #s {width: 252px;float: right;margin-left: 0;}
  #header-right .searchform {width:280px;}
  #banner {font-size: 1.4em;}
  #banner-select ul, #banner-select span {width:250px;font-size: 0.5em;}
  .home #banner {margin-bottom:40px;height: 260px;}
  #banner-select ul, #banner-select span {line-height:34px;}
  #banner-select li {margin-top:4px;}
  #banner-select .btn {height:34px;width:34px;background-size: 10px auto;}
  #banner-select {margin-top:10px;}
  #grey_box_1, #grey_box_2 {padding:20px;border:none;width:100%;float:none;border-bottom:4px solid #fff;}
  #grey_box_1:after, #grey_box_2:after {content:none;display:none;}
  #experts-numbers {margin-top: 20px;}
  .count-container .box {width:100% !important;float:none !important;margin-top: 20px;}
  .count-container .box h4 {text-align:center;width: 190px;margin: 0 auto;padding-top: 10px;min-height: 0;padding-bottom: 10px;}
  .count-container .box div div {font-size:2.8em;}
  .count-container .box img {margin:0 auto;}
  .count {float:none;}
  .count > span > span {text-align:center;}
  .count-container .box > div {width:100%;}
  #footer .menu {float:left;clear:both;padding-top: 10px;}
  #footer .menu ul {text-align:left;}
  #logo2 {position:static;float:right;margin:0;left: auto;top: auto;}
  #banner {height:174px}
  #right-intro, #left-img {width:100%;float:none;}
  #submenu {padding: 0 20px !important;}
  #submenu ul {display:block;overflow:hidden;}
  #submenu li {width:100%;float:none;border:none;padding: 0;margin: 0 !important;text-align:left;}
  #submenu-over.rev #submenu li a, #intro #submenu li a {border-top-color:#e3e3e3;}
  #submenu li a {padding: 17px 45px 17px 30px;display: block;border-top: 1px solid #000;position: relative;}
  #submenu li:first-child a {border-top:0;}
  #submenu li a:before {content:"\25BC";display:block;float:left;position:absolute;right: 20px;top: 50%;margin-top:-0.5em;}
  #submenu li.current_page_item a:before {content:"\25B2"}
  
  #submenu a:after {content:none;display:none;}
  .competence {margin: 10px auto !important;position: static;left: auto;width:182px !important;height: 182px !important;font-size: 0.69em !important;background-size:auto 57px !important;}
  #competences {height:auto;margin: 0;padding: 0;}
  .competence.active > div {opacity:1 !important;-webkit-transform: scale(1);}
  .competence > div {opacity:1 !important;-webkit-transform: scale(1);-ms-transform: scale(1);transform: scale(1);opacity: 1;}
  .competence {background-color:#CF0A2C !important;background-image: none !important;}
  #intro {padding-bottom: 0 !important;}
  #content h2 {font-size:1.6em;}
  .offer {width:100%;float:none;border-left:none !important;;}
  .offer-more .offer-text {
    -moz-column-count: 1;-moz-column-gap: auto;
    -ms-column-count: 1;-ms-column-gap: auto;
    -webkit-column-count: 1;-webkit-column-gap: auto;
    column-count: 1;column-gap: auto;   
  }
  #offers-intro {padding:20px;}
  #offer-form > div > * {margin: 0 0 10px 0 !important;width:100%;float:none;}
  #footer {margin-top:40px;}
  #right-intro .content {padding:20px;}
  #offer-form-over, #offers {margin: 20px 20px 0 20px;}
  .person-block {overflow:hidden;}
  .person {position:relative !important;top:auto !important;left:auto !important;bottom:auto !important;width:50%;float:left;}
  .person:after {content: "";display: block;padding-top: 75% !important;}
  #persons-top .person:after, .person:nth-child(4n+1):after, .person:nth-child(4n):after {padding-top: 125% !important;}
  .person:nth-child(4n+3) {clear: left;}
  .person:nth-child(4n) {position:absolute !important;right:0 !important;bottom:0 !important;}
  #persons-search {display:block;width: 280px;}
  #persons-search .block {float:none;width:auto;clear:both;padding-left: 0;}
  #persons-search .select {float:right;width:180px;}
  #experts-blocks > div {width:100%;border-bottom:2px solid #fff;border-left:none;}
  .experts-submore-links > div {width:50%;border-bottom:3px solid #fff;}
  .experts-submore-links > div:nth-child(2n+1) {border-left:none;}
  #submenu-over {padding-bottom:20px;}
  .page-id-183 #submenu-over, .page-id-29 #submenu-over {padding-bottom:0;}
  #submenu-over #submenu {float: none;}
  #nos_engagements .box {float:none !important;}
  #nos_engagements .arr-down {margin-top: 20px;}
  #honoraires-content, #honoraires-menu {width:100%;float: none;}
  #honoraires > * {height: auto;}
  #honoraires-menu .box {height:auto;padding:20px 0;}
  #honoraires-content .box-text, #honoraires-content .box {position:static;display:none;}
  #honoraires-content .box-text {padding:20px;}
  #honoraires-content .active .box-text, #honoraires-content .box.active  {display:block;}
  .office .icon {width:24px;height:35px;left:-12px;top:-35px;}
  #office-134 .icon {height:45px;width: 30px;left: -15px;top: -45px;}
  .office-name {font-size:0.5em;}
  #office-141 .office-name {left:-28px;}
  #office-141 .office-name > span {left:0;}
  #office-134 .office-name {font-size:0.7em;}
  .office-name > span {padding:0 3px;letter-spacing:0;}
  .office-details {width: 240px;margin-left: -120px;}
  #office-158 .office-details, #office-160 .office-details, #office-141 .office-details {margin-left: auto;left: auto;right: -30px;}
  #office-139 .office-details, #office-143 .office-details, #office-148 .office-details, #office-154 .office-details {margin-left: auto;left: -30px;}
  #office-156 .office-details {margin-left: -100px;}
  #office-170 .office-name {right: 0;left: auto;}
  #office-170 .office-name > span {left: 50%;}
  #interventions, #video, #person {display: block;height: auto;}
  #inter-menu, #inter-links, #video > div, #person > div {display: block;width: 100%;overflow: hidden;}
  #inter-image {display: none;}
  #inter-links, #video-l, #person-details {padding:20px;}
  #video-l .title {padding-top:0;}
  #inter-top .main-title:before {width:280px;margin-left:-140px;}
  #inter-top .main-title:after {margin-left:-100px}
  #inter-menu li {border-right:2px solid #e4e4e4;}
  #inter-menu li:nth-child(2n+2) {border-right:none;}
  #inter-content {margin-top:20px;}
  .inter-l, .inter-r {width:100%;float:none;padding:0px 20px 20px 20px}
  .inter-l:after, .inter-r:after {display:none;content:none;}
  #scroll-down {display: none !important;}
  #person-image {padding-top:53%;background-size:100% auto;background-position:center top;}
  #person-info {padding: 20px 20px 0 20px;text-align: left;font-size: 1em;}
  #person-info div {margin-top:20px;}
  .faq {width:50%;}
  .faq.row2:nth-child(2n) > .faq-answer {left: auto;right: 0;}
}
@media (max-width:499px) {
  .faq {width:100%;padding-top: 40px;float: none;}
  .faq:after {content:none;}
  .faq-pdf > a, .faq > strong, .faq > span {position: relative;top: auto;padding-left:10px;padding-right:10px;}
  .faq.row2 > .faq-answer {width: 100%;}
  .faq.row2:nth-child(2n) > .faq-answer {left: 0;right: auto;}
  .faq-pdf > a > span, .faq > .faq-answer, .faq.active > .faq-answer > div {position:static;height: auto;}
  .faq-pdf {padding-bottom:60px;padding-top:60px;}
  .faq.active > .faq-answer > div > div, .faq.active > .faq-answer {padding-left: 60px;}
  .faq > span {margin-top:15px;padding-bottom: 40px;}
}



/* new 13/01/2016 */
#office-834 { top:22%; right: 27%; }
#office-1179 { top:22%; right: 27%; }
#office-1152 {left: 50%;top: 19%;}
#office-1152 .icon {height:108px;width: 70px;left: -35px;top: -108px;}
#office-1152 .office-name {font-size:1.25em;}
#office-1154 {left: 38%;top: 17%;}
#office-1157 {left: 19%;top: 23%;}
#office-1158 {left: 86%;top: 30%;}
#office-1170 {left: 19%;top: 40%;}
#office-1171 {left: 62%;top: 39%;}
#office-1172 {left: 24%;top: 62%;}
#office-1173 {left: 50%;top: 55%;}
#office-1173 .office-name > span {max-width: 120px;}
#office-1174 {left: 62%;top: 56%;}
#office-1175 {left: 17%;top: 79%;}
#office-1176 {left: 37%;top: 86%;}
#office-1177 {left: 73%;top: 90%;white-space:nowrap;}
#office-1178 {left: 82%;top: 82%;}
#office-1185 {left: 44%;top: 18%;}
#office-1186 {left: 50%;top: 22%;}
#office-1187 {left: 39%;top: 26%;}
#office-1188 {left: 40%;top: 40%;}
#office-1189 {left: 86%;top: 40%;}
#office-3599 {left: 60%;top: 17%;}
#office-1189 .office-name {white-space:nowrap;}
#office-1189 .office-details {margin-left: -207px;}
#office-1188 .icon {background-image:url("images/marker-rev.png");position:absolute;-webkit-transform-origin:50% 0%;-ms-transform-origin:50% 0%;transform-origin:50% 0%;top: 0;}
#office-1188 .office-name {top: auto;bottom: 5px;}
#office-1188 .office-details {margin-top: -92px;}
.office-details p {
	margin:0;
}
.office-details a {
	margin-bottom: 0 !important;
}
.office-details hr {
	margin:10px auto !important;
}
h3.invisible {
	opacity: 0; 
}

p > iframe {
	display: block;
	margin:0 auto;
}
ul.archive {
	list-style: none;
	text-align: center;
	padding:30px 0 10px;
}
ul.archive li { display: inline-block; }
ul.archive li a {
	display: block;
	line-height: 22px;
	padding:3px 24px 0;
	background: #000;
	color: #fff;
}
ul.archive li.current a {
	background: #e3e3e3;
	color: #CF0A2C;
}

.offer {
	position: relative;
}
.offer .icon {
	width: 32px;
	margin:0 0 12px;
}
@media (min-width:1024px) { 
	.offer .icon {
		position: absolute;
		left: 17px;
		top:25px;
		margin:0;
	}
}
.offer .subtitle {
	color:#CF0A2C !important;
}
#menu li.page-item-1031 ul {
	display: none  !important;
}
.page-id-1146 #offices {max-width:912px;}
.page-id-1148 #offices {max-width:1276px;}


.page-item-1203 a {opacity: 0.5;display: inline-block;}


#inter-menu li:nth-child(1) a {background-image:url("images/prof-1.png");}
#inter-menu li:nth-child(2) a {background-image:url("images/prof-2.png");background-size: auto 34px;background-position: center 11px;}
#inter-menu li:nth-child(3) a {background-image:url("images/prof-3.png");background-size: auto 47px;background-position: center 5px;}
#inter-menu .page-item-201 a {background-image:url("images/prof-4.png");background-size: auto 36px;background-position: center 10px;}
#inter-menu li:nth-child(4) a {background-image:url("images/prof-5.png");background-size: auto 27px;background-position: center 16px;}
#inter-menu li:nth-child(5) a {background-image:url("images/prof-6.png");background-size: auto 32px;background-position: center 13px;}
#inter-menu li:nth-child(6) a {background-image:url("images/prof-7.png");background-size: auto 31px;background-position: center 13px;}





