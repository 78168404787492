.town{
    position: relative;
    background-color: $beige;
    color: $black;
    li {
        list-style: none;
    }
    a{
        &:hover{
            color: $white;
        }
    }
}
.town-bg__container{
    margin: auto;
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
}

.town-bg__image{
    width: 100%;
    height: auto;

    position: absolute;
    left: 0;
    top: 0;
}
.town-info__container{
    position: absolute;
    top: 0;
    left: 90px;
    height: 100vh;
    width: 30%;
}
.town-info__line {
    position: absolute;
    left: -2px;
    width: 2px;
    height: 33%;
    top: 0;
    background-color: $white;
}

.town__info{
    position: absolute;
    top: 15%;
    left: 0;
    width: 100%;
    height: 540px;
    padding: 65px;
    z-index: 10;
    
    background-color: $dark-red;
    color: $white;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    
}

.town__container{
    
    max-width: 1260px;
    margin: auto;
    position: relative;
}

.town-info__title{
    font-weight: 900 !important;
    font-size: 60px;
    letter-spacing: -1.04px;
    line-height: 62px;
    text-transform: uppercase;

}
.town-info__status{
    text-transform: uppercase;
    font-size: 39px;
    letter-spacing: -.56px;
    line-height: 39px;

    margin-bottom: 40px;
}
.town-info__adress, .town-info__phone{
    font-size: 17px;
    letter-spacing: -.3px;
    line-height: 20px;
}

.town-info__adress{
    margin-bottom: 10px;
    &::before{
        content: url(/wp-content/themes/add/images/town_adress_logo.svg);;
        width: 20px;
        height: 20px;
        margin-right: 20px;
    }
}
.town-info__phone{
    &::before{
        content: url(/wp-content/themes/add/images/town_phone_logo.svg);;
        width: 20px;
        height: 20px;
        margin-right: 20px;
    }
}


.town-info__links{
    position: relative;
    padding-left: 0;
    width:100% ;
}

.town-info__links-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -.24px;
    line-height: 16px;
    opacity: 0.5;
    transform: translateX(0);

    transition: opacity .7s ease-in-out, transform .7s ease-in-out;

    padding: 15px 0;
    width: 100%;

    &::after{
        content: "";
        width: 13px;
        height: 13px;
        background-image: url(/wp-content/themes/add/images/town-arrow.svg);
        background-repeat: no-repeat;

    }

    &:first-child{
        margin-top: 0;
    }

    &.isActive{
        opacity: 1;
        transform: translateX(30px);
    }
}

.town__content{
    padding-top: 150px;
    width: 45%;
    margin-left: auto;
}
.town-content__image-container {
    position: relative;
    width: 300px;
    height: 300px;
    overflow: hidden;
    border-radius: 50%;

    margin-bottom: 30px;
}
.town-content__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: auto;
    max-width: unset;
}

.town-content__name{
    font-weight: 500;
    font-size: 28px;
    letter-spacing: -.29px;
    line-height: 34px;
    color: $red;
    text-transform: uppercase;
}

.town-content__position{
    font-size: 18px;
    letter-spacing: -.31px;
    line-height: 21px;
    text-transform: uppercase;


    margin-bottom: 50px;
}

.town-content__citation{
    font-weight: 500;
    font-size: 24px;
    letter-spacing: -.24px;
    line-height: 34px;
    margin-bottom: 25px;

}

.town-content__paragraph{
    font-size: 18px;
    letter-spacing: -.31px;
    line-height: 21px;
    p{
        margin-bottom: 50px;
    }

}
.town-content__social-contact{
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -.24px;
    line-height: 16px;
    a{
        display: block;
        margin-bottom: 5px;
    }
}
.town-content__social{
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    border-top: 1px solid $grey-light;
    padding-top: 40px;
}

.town__map {
    position: relative;
    margin-top: 150px;
      
    height: 85vh; 
    width: 67.5vw;
    max-width: 1000px;
    max-height: 700px;

    margin-right: 0;
    margin-left: auto;

    a{
        display: none !important;
    }
}
.town__contact {
    height: 85vh; 
    width: 67.5vw;
    max-width: 1000px;
    max-height: 700px;

    margin-right: 0;
    margin-left: auto;

    background-image: url(/wp-content/themes/add/images/town-contact-bg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.town-contact__form{
    padding: 5% 15% 0% 35%;

    .town-contact__name{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
    }

    input, #message{
        display: block;
        background-color: transparent;
        color: $white;
        font-size: 14px;
        letter-spacing: -.24px;
        line-height: 16px;
        border: none;

        padding-bottom: 15px;
        border-bottom: 1px solid rgba(255, 255,255, 0.3);

        &::placeholder{
            opacity: 1;
            color: $white;
        }

    }

    #message{
        margin-top: 30px;
        margin-bottom: 40px;
        width: 100%;
        height: 185px;
        resize: none;
    }

    .town-contact__submit{
        border: none;
        text-transform: uppercase;
    }
}

@media screen and (max-width: 769px) {
    .town-info__container{
        position: absolute;
        top: 17%;
        right: 90px;
        left: unset;
        width: 60%;
    }
    
    
    
    .town-info__links{
        display: none;
    }
    
    .town__info{
        position: absolute;
        top: 0;
        left: auto;    
        right: 0;
        width: 100%;
        height: fit-content;
        transform: translateY(-50%);
    }
    
    .town__container{
        max-width: 80%;
        margin: auto;
        position: relative;
    }
    
    .town__content{
        padding-top: 250px;
        width: 100%;
        margin-left: auto;
    }
    
    .town__map {
        height: 85vh; 
        width: 75.5vw;
        max-width: 1000px;
        max-height: 550px;
    }
    .town__contact {
        height: 85vh; 
        width: 75.5vw;
        max-width: 1000px;
        max-height: 550px;
    }
    
    .town-contact__form{
        padding: 15% 15% 15% 15%;
    }
}
@media screen and (max-width: 500px) {
    .town-contact__form {
        .town-contact__name {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 40px;
            
            input {
                margin-bottom: 20px;

                &:last-child{
                    margin-bottom :0;
                }
            }
        }

    }

    .town-info__container{
        position: absolute;
        top: 40vh;
        right: 0;
        left: 0;
        width: 80%;

        margin: 0 auto;
    }

    .town__map {
        height: 85vh; 
        width: 100%;
    }
    .town__contact {
        height: 85vh; 
        width: 100%;
    }

    .town-info__title {
        font-size: 35px;
        letter-spacing: -0.54px;
        line-height: 45px;
    
    }
    .town-info__status {
        font-size: 24px;
        letter-spacing: -.36px;
        line-height: 28px;
    }

    .town-info__adress, .town-info__phone {
        font-size: 14px;
        letter-spacing: -.19px;
        line-height: 18px;
    }

    .town__content {
        padding-top: 50px;
    }

    .town-content__name, .town-content__position{
        text-align: center;
    }
    .town-content__citation {
        font-size: 18px;
        letter-spacing: -.19px;
        line-height: 27px;
    }

    .town-content__paragraph {
        font-size: 14px;
        letter-spacing: -.21px;
        line-height: 16px;
    }

}