
.blog{
    background-color: $beige;
    color: $black;
    li {
        list-style: none;
    }
}
.blog__container{
    max-width: 1260px;
    margin: auto;
}
.blog-filters{
    border-top: 2px solid $beige ;
    background-color: $white;
    width: 100%;
    padding: 20px 0;
}
.blog-filters__list{
    width: 60%;
    margin: auto;
    padding-left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.blog-filters__list-item{
    position: relative;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 13px;
    letter-spacing: -0.23px;
    line-height: 15px;

    &:after{
        content: "";
        position: absolute;
        left: 0;
        bottom: -21px;
        transform-origin: left;
        width: 100%;
        height: 2px;
        background-color: $red;
        opacity: 0;
    }

    &.active{
        font-weight: 900;
        color: $red;
        &:after{
            opacity: 1;
        }
    }

}
.blog__main{
    padding-top: 100px;
    display: flex;
    align-items: center;
    width: 100%;
}
.main-article{
    width: 65%;
    position: relative;
    overflow: hidden;
}
.main-article__image{
    width: 100%;
    height: auto;
}

.main-article__heading{
    position: absolute;
    bottom: 50px;
    left: 50px;
    width: 400px;
}
.main-article__title{
    background-color: $white;
    display: inline !important;
    padding: 0;
    box-shadow: 5px 0 0 $white, -5px 0 0 $white;

    font-size: 21px;
    line-height: 24px;
    letter-spacing: -0.37px;
    font-weight: 800 !important;
}

.others{
    width: 35%;
}

.others__list{
    padding-left: 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.others__list-item{
    position: relative;
    width: 100%;
    overflow: hidden;
}

.others__image{
    width: 100%;
    height: auto;
}

.others__heading{
    position: absolute;
    left: 30px;
    bottom: 30px;
    max-width: 300px
}

.others__title{
    display: inline;
    background: $white;
    font-size: 14px;
    letter-spacing: -0.23px;
    font-weight: 800 !important;
    box-shadow: 5px 0 0 $white, -5px 0 0 $white;
    padding: 5px 0;
}
.blog__second-wrapper{
    display: flex;
}
.blog__second-articles{
    margin-top: 30px;
    width: 65%;
}

.second-articles__list{
    padding-left: 0;
}

.second-articles__list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 30px;
    margin-bottom: 30px;

    border-bottom: 1px solid rgba(151,151,151,.3);

}

.second-articles__image-container{
    width: 48%;
}

.second-articles__content{
    width: 46%;
    margin: auto;
}
.second-articles__date{
    margin-bottom: 10px;
    font-weight: 500;
    letter-spacing: -0.19px;
    line-height: 10px;
    font-size: 11px;
    text-transform: uppercase;
}

.second-articles__title{
    font-weight: 900 !important;
    letter-spacing: -0.23px;
    line-height: 14px;
    font-size: 13px;
    text-transform: uppercase;

    margin-bottom: 20px;
}

.second-articles__paragraph{
    font-weight: normal;
    letter-spacing: -0.21px;
    line-height: 16px;
    font-size: 12px;

    overflow: hidden;
    width:100%;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;

    margin-bottom: 20px;
}

.second-articles__link{
    font-weight: 500;
    letter-spacing: -0.19px;
    line-height: 10px;
    font-size: 11px;
    text-transform: uppercase;
    color: $red;
}

.blog__twitter{
    margin-top: 30px;
    width: 35%;
}

.twitter__heading {
    text-align: center;
    font-weight: 900;
    font-size: 14px;
    letter-spacing: -0.24px;
    color: $black;
    border: 1px solid $grey-light;
    padding: 30px 0;
    margin-left: 2.3em;
}
.twitter__list {
    padding-left: 2em;
}

.twitter__list-item{
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.19px;
    color: $grey;

    padding: 30px 0;
    border-bottom: 1px solid $grey-light;

    .twitter__date{
        color: $black;
    }

    .twitter__content{
        color: $black;
        padding-top: 20px;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.21px;
    }
}

.twitter__logo{
    display: inline-block;
    width: 13px;
    height: 13px;
    background-image: url(/wp-content/themes/add/images/Twitter-logo.svg);
    background-position: center;
    background-repeat: no-repeat;

}


.pagination-web{
    margin: 0 auto;
}

nav{ 
    &.pagination{
        height: 60px;
        clear: both;
        width: 100%;
        margin: 0 auto;
        float: left;
    }
    .pagination-web{
        margin: 0 auto;
    }
    &.pagination ul{
        list-style-type:none;
        float:left;
        margin:0;
        padding: 0;
    }
    &.pagination ul li{
        display:inline-block;
        margin: 0;
        padding: 0;
    }
    &.pagination ul li a{
        padding:5px 11px;
        display:block;
        color:#000;
        border-radius:2px;
        text-decoration:none;
        font-size:20px;
        font-weight:500;
    }
    &.pagination ul li.active a{
        color:#e00021;
    }
    &.pagination ul li a:hover{
    }
    &.pagination span{
        float:right;
        margin:20px 20px 0 0;
        font-size:13px;
        font-weight:500;
        color:#999;
    }
}
@media screen and (max-width: 900px) {
    .blog__container{
        max-width: 80%;
        margin: auto;
    }

    .blog-filters__list{
        width: 70%;
    }
    .blog-filters__list-item{
        font-size: 11px;
    }

    .blog__main {
        padding-top: 50px;
        flex-direction: column;
    }
    .main-article {
        width: 100%
    }
    .others{
        width: 100%;
        margin-top: 20px;
    }

    .others__list {
        padding-left: 0;
        flex-direction: row;
    }
    .others__list-item {
        width: 48%;
    }
    .others__title{
        font-size: 12px;
    }
    .blog__second-wrapper {
        flex-direction: column;
    }
    .blog__second-articles {
        margin-top: 40px;
        width: 100%;
    }
    .second-articles__image-container {
        width: 53%;
    }
    .second-articles__content {
        width: 42%;
    }
    .second-articles__paragraph {
        margin-bottom: 10px;
    }

    .blog__twitter{
        width: 100%;
    }
    .twitter__heading{
        margin-left: 0;
    }
    .twitter__list{
        padding-left: 0;
    }
  }

@media screen and (max-width: 500px) {
.second-articles__list-item {
    flex-direction: column;
}
.second-articles__image-container {
    width: 100%;
    margin-bottom: 10px;
}
.second-articles__content {
    width: 100%;
}
.main-article__heading {
    position: absolute;
    bottom: 25px;
    left: 25px;
    max-width: 200px;
}
.main-article__title {
    padding: 5px 0;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: -0.21px;
}
.others__heading {
    position: absolute;
    left: 15px;
    bottom: 15px;
    max-width: 300px;
}
.others__title {
    font-size: 11px;
    letter-spacing: -.19px;
}

.blog-filters__list-item {
    text-align: center;
    font-size: 10px;
    letter-spacing: -.19px;
}
.blog-filters__list{
    width: 80%;
}

}