// Fonts
$gotham: 'Gotham';


// Colors

$white: #ffffff;
$red: #E00021;
$dark-red: #CF0B2C;
$grey: #979797;
$grey-light: #E1E1E1;
$beige: #F8F8F8;
$black: #3C3C3C;