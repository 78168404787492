.single-article{
    background-color: $beige;
    color: $black;
    li {
        list-style: none;
    }
}
.single-article__container{
    max-width: 1260px;
    margin: auto;
}

.single-article__wrapper{
    width: 100%;
    display: flex;
}
.single-article__content{
    width: 60%
}
.breadcrumb{
    padding: 20px 0;
}
.breadcrumb__list{
    display: flex;
    align-items: center;

    padding-left: 0;

    font-size: 11px;
    font-weight: normal;
    letter-spacing: -0.19px;
    line-height: 14px;
}
.breadcrumb__list-item{
    margin-right: 5px;

    &::before{
        content: "/";
        margin-right: 3px;
        
    }
    &:first-child{
        &::before{
            content: "";
            margin-right: 0;
        }
    }
}
.article__intro{
    margin-bottom: 50px;
}
.article__intro-content{
    margin-top: 40px;
}
.intro__date{
    font-weight: 500;
    letter-spacing: -0.19px;
    line-height: 10px;
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.intro__title{
    font-weight: 900 !important;
    letter-spacing: -0.61px;
    line-height: 38px;
    font-size: 35px;
    text-transform: uppercase;

    max-width: 90%;

    color: $red;
    margin-bottom: 30px;
}

.single-article__share{
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    padding: 30px 0;

    border-bottom: 1px solid $grey-light;
    border-top: 1px solid $grey-light;
}
.share__text{
    font-weight: 500;
    letter-spacing: -0.28px;
    line-height: 19px;
    font-size: 16px;
    text-transform: uppercase;
}


// Block Article
.article-block-content{
    margin-bottom: 50px;
    h3{
        font-weight: 500;
        letter-spacing: -0.37px;
        line-height: 25px;
        font-size: 21px;
        margin-bottom: 30px;
    }

    p{
        font-weight: normal;
        letter-spacing: -0.28px;
        line-height: 28px;
        font-size: 16px;
        margin-bottom: 40px;
    }

    figure{
        width: 100%;
        margin: 50px 0 40px;
    }

    blockquote p,
    .block-content__citation{
        font-weight: 500; 
        letter-spacing: -0.37px;
        line-height: 23px;
        font-size: 21px;
        color: #AAAAAA;


        margin-top: 50px;
        padding-left: 40px;
        border-left: 2px solid $grey-light ;
    }
    
    .intro__paragraph{
        font-weight: normal;
        letter-spacing: -0.42px;
        line-height: 30px;
        font-size: 24px;
    }

    .block-content__image-container{
        width: 100%;
        margin-top: 50px;
    }

    .block-content__image{
        width: 100%;
    }
    .block-content__image-caption{
        font-weight: normal;
        letter-spacing: -0.24px;
        line-height: 16px;
        font-size: 14px;
    }
}


.single-article__side {
    width: 40%;
}

.single-article__twitter{
    width: 100%;

    .twitter__heading {
        text-align: center;
        font-weight: 900;
        font-size: 14px;
        letter-spacing: -0.24px;
        color: $black;
        border: 1px solid $grey-light;
        padding: 30px 0;
        margin-left: 2.3em;
    }
    .twitter__list {
        padding-left: 2em;
    }
    
    .twitter__list-item{
        font-size: 11px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.19px;
        color: $grey;
    
        padding: 30px 0;
        border-bottom: 1px solid $grey-light;
    
        .twitter__date{
            color: $black;
        }
    
        .twitter__content{
            color: $black;
            padding-top: 20px;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.21px;
        }
    }
    
    .twitter__logo{
        display: inline-block;
        width: 13px;
        height: 13px;
        background-image: url(/wp-content/themes/add/images/Twitter-logo.svg);
        background-position: center;
        background-repeat: no-repeat;
    
    }
}

.side-articles{
    margin-top: 50px;
    width: 100%;
}

.side-articles__list{
    padding-left: 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.side-articles__list-item{
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
}

.side-articles__image{
    width: 100%;
    height: auto;
}

.side-articles__heading{
    position: absolute;
    left: 0;
    bottom: 30px;
    max-width: 350px;
    background: $white;
    padding: 20px;
}

.side-articles__title{
    font-size: 14px;
    letter-spacing: -0.23px;
    font-weight: 900 !important;
    
}
.side-articles__type{
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.17px;
    font-weight: 900;
    margin-bottom: 5px;
    color: $grey-light;
    text-transform: uppercase;
    
}

@media screen and (max-width: 900px) {
    .single-article__container{
        max-width: 80%;
        margin: auto;
    }

    .single-article__wrapper {
        flex-direction: column;
    }
    .single-article__content, .single-article__side {
        margin-top: 50px;
        width: 100%;
    }
    .single-article__twitter{
        .twitter__heading{
            margin-left: 0;
        }
    
        .twitter__list{
            padding-left: 0;
        }

    }
    .side-articles__list{
        padding-left: 0;
    }
}
@media screen and (max-width: 500px) {
    .breadcrumb__list-item{
        white-space : nowrap;
        width: 100px;
        text-overflow : ellipsis;
        overflow: hidden;
    }

    .intro__date{
        letter-spacing: -0.17px;
        line-height: 10px;
        font-size: 10px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .intro__title{
        font-weight: 900 !important;
        letter-spacing: -0.35px;
        line-height: 30px;
        font-size: 20px;
       
        margin-bottom: 20px;
    }
    
    .intro__paragraph{
        font-weight: normal;
        letter-spacing: -0.32px;
        line-height: 24px;
        font-size: 14px;
    }
    .single-article__share{
        width: 100%;
    
        padding: 30px 0;
    
        border-bottom: 1px solid $grey-light;
        border-top: 1px solid $grey-light;
    }
    .share__text{
        font-weight: 500;
        letter-spacing: -0.18px;
        line-height: 16px;
        font-size: 12px;
        text-transform: uppercase;
    }

    .share__media{
        img{
            width: 25px;
            height:25px;
        }
    }

    .article-block-content{
        margin-bottom: 50px;
        .block-content__question{
            letter-spacing: -0.21px;
            line-height: 20px;
            font-size: 16px;
            margin-bottom: 30px;
        }
        .block-content__citation{
            letter-spacing: -0.21px;
            line-height: 20px;
            font-size: 16px;
    
    
            margin-top: 30px;
            padding-left: 40px;
            border-left: 2px solid $grey-light ;
        }
    
        .block-content__paragraph{
            letter-spacing: -0.19px;
            line-height: 20px;
            font-size: 14px;
            margin-bottom: 20px;
        }

        .block-content__image-caption{
            font-weight: normal;
            letter-spacing: -0.18px;
            line-height: 12px;
            font-size: 10px;
        }
    }

    .side-articles__title{
        font-size: 12px;
        letter-spacing: -0.19px;
        font-weight: 900 !important;
        
    }
    .side-articles__type{
        font-size: 10px;
        line-height: 12px;
        letter-spacing: -0.17px;
        font-weight: 900;
        margin-bottom: 5px;
        color: $grey-light;
        text-transform: uppercase;
        
    }

    .side-articles__heading{
        position: absolute;
        left: 0;
        bottom: 10px;
        max-width: 350px;
        background: $white;
        padding: 10px;
    }

}